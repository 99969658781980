import {
  LogErrorMessage,
  LogFinished,
  LogFinishedInactivity,
  LogInfo,
  LogStarted,
} from "@workers/work-log/messages";
import { Controller, ActionEvent } from "@hotwired/stimulus";
import { ConsumerManager } from "@workers/work-log/message-managers";
import { Message } from "@workers/interfaces";

import { useDebounce } from "stimulus-use";

export default class extends Controller {
  declare worker: SharedWorker;
  declare manager: ConsumerManager;
  static values = {
    ruta: String,
    idHistorial: Number,
    isSuper: Boolean,
    idJornada: Number,
    tiempoMaximoInactividad: Number,
    tiempoActualizar: Number,
    tiempoRefrescar: Number,
  };
  static targets = [
    "estatus",
    "horaInicio",
    "horaFinal",
    "tiempoTrabajado",
    "circle",
    "divHoraInicio",
    "divHoraFinal",
    "divIntervalo",
    "btnDetener",
    "modalMSJVarios",
    "modalCerrarSesion",
    "btnAceptar",
  ];

  static debounces = [
    {
      name: "reportarInactividad",
      wait: 1000,
    }
  ];
  static classes = ["success", "warning", "dark", "hide"];
  declare rutaValue: string;
  declare idJornadaValue: number;
  declare isSuperValue: boolean;
  declare tiempoMaximoInactividadValue: number;
  declare tiempoActualizarValue: number;
  declare tiempoRefrescarValue: number;

  declare estatusTarget: HTMLOutputElement;
  declare horaInicioTarget: HTMLOutputElement;
  declare horaFinalTarget: HTMLOutputElement;
  declare tiempoTrabajadoTarget: HTMLOutputElement;
  declare circleTarget: HTMLElement;
  declare divHoraInicioTarget: HTMLDivElement;
  declare divHoraFinalTarget: HTMLDivElement;
  declare divIntervaloTarget: HTMLDivElement;
  declare btnDetenerTarget: HTMLButtonElement;
  declare modalMSJVariosTarget: HTMLDivElement;
  declare modalCerrarSesionTarget: HTMLDivElement;

  declare btnAceptarTarget: HTMLAnchorElement;
  //clases
  declare successClass: string;
  declare warningClass: string;
  declare darkClass: string;
  declare hideClass: string;

  declare isActive: boolean;
  declare isChangeCiaClicked: boolean;
  declare isStopWorkdayClicked: boolean;
  declare isLogoutClicked: boolean;

  connect(): void {
    this.isActive = false;
    this.manager = new ConsumerManager({
      LogStarted: (m: Message) => {
        const logStartedMessage = m as LogStarted;
        this.idJornadaValue = logStartedMessage.idHistorial;
        this.postJornadaActiva(
          this.getTiempo(logStartedMessage.date),
          logStartedMessage.message
        );
        this.isActive = true;
      },

      LogFinished: (m: Message) => {
        const logFinishedMessage = m as LogFinished;
        this.postLogFinished(
          this.getTiempo(logFinishedMessage.date),
          logFinishedMessage.interval,
          logFinishedMessage.message
        );
        this.showModal(logFinishedMessage.message);
        this.isActive = false;
        this.idJornadaValue = 0;
      },

      LogFinishedInactivity: (m: Message) => {
        const logFinishedMessage = m as LogFinishedInactivity;
        this.postLogFinished(
          this.getTiempo(logFinishedMessage.date),
          logFinishedMessage.interval,
          logFinishedMessage.message
        );
        this.isActive = false;
        this.redirectLogin();
      },

      LogInfo: (m: Message) => {
        this.isActive = true;
        const logInfoMessage = m as LogInfo;
        this.postJornadaActiva(
          this.getTiempo(logInfoMessage.date),
          logInfoMessage.message
        );
      },

      LogErrorMessage: (m: Message) => {
        const errorMessage = m as LogErrorMessage;
        this.showModal(errorMessage.message);
      },
    });

    if (this.isSuperValue) {
      return;
    }
    this.worker = new SharedWorker(
      new URL("@workers/work-log/worker.js", import.meta.url),
      {
        name: "worklog-worker",
      }
    );

    this.manager.assignPort(this.worker.port);
    this.worker.port.start();
    //debounce
    useDebounce(this);
    //Tiempo en milisegundos
    // 15min
    const tiempoMaximoInactividad = 15*60*1000;
    // 2min
    const tiempoActualizar = 2*60*1000;

    this.manager.initWorker(
      this.rutaValue,
      this.idJornadaValue,
      tiempoActualizar,
      tiempoMaximoInactividad
    );

    if (this.idJornadaValue) {
      this.manager.requestLog(this.idJornadaValue);
    }
  }

  disconnect() {
    this.worker.port.postMessage({ action: "die" });
  }

  logStart(e: ActionEvent) {
    if (this.isSuperValue) {
      return;
    }
    this.manager.startLog();
  }
  logStop() {
    if (this.idJornadaValue == 0 || this.isSuperValue) {
      return;
    }
    this.manager.stopLog();
  }
  logRequest() {
    if (this.idJornadaValue == 0 || this.isSuperValue) {
      return;
    }
    this.manager.requestLog(this.idJornadaValue);
  }

  //Carga los datos cuando la joranda es iniciada
  postJornadaActiva(fecha: string, mensaje: string) {
    this.circleTarget.classList.remove(...[this.darkClass, this.warningClass]);
    this.estatusTarget.innerHTML = mensaje;
    this.circleTarget.classList.add(this.successClass);
    this.divHoraFinalTarget.classList.add(this.hideClass);
    this.divIntervaloTarget.classList.add(this.hideClass);
    this.divHoraInicioTarget.classList.remove(this.hideClass);
    this.horaInicioTarget.innerHTML = fecha;
    this.btnDetenerTarget.classList.remove(this.hideClass);
  }

  //Carga los datos cuando la jornada es finalizada
  postLogFinished(fecha: string, intervalo: string, mensaje: string) {
    this.circleTarget.classList.remove(this.successClass);
    this.circleTarget.classList.add(this.warningClass);
    this.estatusTarget.innerHTML = mensaje;
    this.divHoraFinalTarget.classList.remove(this.hideClass);
    this.horaFinalTarget.innerHTML = fecha;
    this.divIntervaloTarget.classList.remove(this.hideClass);
    this.tiempoTrabajadoTarget.innerHTML = intervalo;
    this.btnDetenerTarget.classList.add(this.hideClass);
  }

  getTiempo(fecha: Date) {
    return fecha.toLocaleTimeString("en-US").toString();
  }

  reportarInactividad() {
    if (this.isActive) {
      this.manager.PingLog();
    }
  }

  //Muestra el modal de mensajes varios
  showModal(mensaje: string) {
    const modalBody = this.modalMSJVariosTarget.querySelector(".modal-body");
    if (modalBody) {
      modalBody.innerHTML = mensaje;
    }
    $(this.modalMSJVariosTarget).modal("show");
  }

  //Mostrar modal si va a cerrar sesion
  showLogoutConfirm(event: ActionEvent) {
    if (this.isActive && this.idJornadaValue != 0) {
      this.setModalMessage(
        event,
        "¿Desea cerrar sesión? Si tiene una jornada activa, la misma se detendrá."
      );
      this.changeStatus();
      this.isLogoutClicked = true;
    }
  }
  //Mostrar modal si va a detener la jornada
  showStopConfirm(event: ActionEvent) {
    this.setModalMessage(
      event,
      "¿Desea detener la jornada? Si lo hace, volverá al menú principal"
    );
    this.changeStatus();
    this.isStopWorkdayClicked = true;
  }

  //mostrar modal si va a cambiar de compañia
  changeCompany(event: ActionEvent) {
    if (!this.isActive && this.idJornadaValue == 0) {
      this.btnAceptarTarget.click();
      return;
    }
    this.changeStatus();
    this.isChangeCiaClicked = true;
    this.setModalMessage(
      event,
      "¿Desea cambiar la compañia? Si lo hace, la jornada actual finalizará"
    );
  }

  //setea los valores clicked en false
  changeStatus() {
    this.isChangeCiaClicked = false;
    this.isLogoutClicked = false;
    this.isStopWorkdayClicked = false;
  }

  //setea el mensaje y lo muestra al usuario si la jornada está activa
  setModalMessage(event: ActionEvent, message: string) {
    const modalBody = this.modalCerrarSesionTarget.querySelector(".modal-body");
    if (modalBody && "textContent" in modalBody) {
      (modalBody as HTMLElement).textContent = message;
    }
    if (this.idJornadaValue !== 0 && this.isActive) {
      $(this.modalCerrarSesionTarget).modal("show");
      event.preventDefault();
    }
  }

  //Redirecciona al login o evita redireccion para que se siga ejecutando el evento click de cambiarCia
  redirectView(event: Event) {
    if (this.idJornadaValue !== 0 && this.isActive) {
      this.logStop();
    }
    if (this.isChangeCiaClicked || this.isStopWorkdayClicked) {
      event.preventDefault();
    } else if (this.isLogoutClicked) {
      this.redirectLogin();
    }
  }

  redirectLogin() {
    let url = this.btnAceptarTarget.href;
    window.location.href = url;
  }
}
